import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSnackBar.figmaUrl.android} codeUrl={checklists.componentSnackBar.codeUrl.android} checklists={checklists.componentSnackBar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Snackbars notify users of processes that have been or will be performed by the app. They appear temporarily, towards the bottom of the screen.
They must not interfere with the user experience, and they do not require user input to disappear.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/android-1.png",
      "alt": "usage snackbar"
    }}></img>
    <div className="divi" />
    <h2>{`Variants & Themes`}</h2>
    <p>{`Legion has 4 variants for snackbar :`}</p>
    <ol>
      <li parentName="ol">{`Title`}</li>
      <li parentName="ol">{`Description`}</li>
      <li parentName="ol">{`Action Text`}</li>
      <li parentName="ol">{`Icon`}</li>
      <li parentName="ol">{`Avatar`}</li>
    </ol>
    <p>{`Legion has 4 themes for snackbar :`}</p>
    <ol>
      <li parentName="ol">{`Basic / Normal Theme`}</li>
      <li parentName="ol">{`Error Theme`}</li>
      <li parentName="ol">{`Warning Theme`}</li>
      <li parentName="ol">{`Success Theme`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Basic use of snackbars in fragments and in activities without customization`}</p>
    <br />
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/android-snackbar.gif",
      "alt": "basic usage"
    }}></img>
    <br />
    <br />
    <h3>{`Normal Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    normalSnackBar("Normal Snackbar")
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Using Extension Function only avalaibe on Fragment or Activity`}</p>
    </InlineNotification>
    <br />
    <h3>{`Success Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    successSnackBar("Success Snackbar")
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Using Extension Function only avalaibe on Fragment or Activity`}</p>
    </InlineNotification>
    <br />
    <h3>{`Error Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    errorSnackBar("Error Snackbar")
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Using Extension Function only avalaibe on Fragment or Activity`}</p>
    </InlineNotification>
    <br />
    <h3>{`Warning Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    warningSnackBar("Warning Snackbar")
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Using Extension Function only avalaibe on Fragment or Activity`}</p>
    </InlineNotification>
    <h2>{`Variants Usage`}</h2>
    <p>{`Further use of the snackbar in fragments and in activities with customization such as:`}</p>
    <ol>
      <li parentName="ol">{`Title`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnSnackBar.setup(this) {
        ...
        title = "Title"
        ...
    }.show()
}

...
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Description`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnSnackBar.setup(this) {
        ...
        description = "Description"
        ...
    }.show()
}

...
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Action Text`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnSnackBar.setup(this) {
        ...
        actionText = "Action Text"
        ...
    }.show()
}


...
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Icon`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnSnackBar.setup(this) {
        ...
        icon = "https://cdn.kincir.com/2/cojC8OAR0YiByhyEPOO6AADzHrc9p3w7CTursS4z35A/transform/rs:fit:764/src/production/2021-02/740x435_3576f9505b43dabaea0294436ab365775c42fe56.jpg"
        ...
    }.show()
}


...
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Avatar`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnSnackBar.setup(this) {
        ...
        avatarUrl = "https://cdn.kincir.com/2/cojC8OAR0YiByhyEPOO6AADzHrc9p3w7CTursS4z35A/transform/rs:fit:764/src/production/2021-02/740x435_3576f9505b43dabaea0294436ab365775c42fe56.jpg"
        ...
    }.show()
}



...
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type Snackbar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View Anchor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`view`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Anchor View`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Description Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Title Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration Snackbar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`actionText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Action Text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon Drawable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Avatar Url`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`avatarUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Avatar Url`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setActionButtonListener { //Action }`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Snackbar Callback Action Button`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      